import { history } from '@umijs/max';
import { useSessionStorageState } from 'ahooks';
import { BiuCue, BUBU100_CACHE_USERINFO_KEY, setToken, clearDB } from 'enjoy-link-antd-repack';
import queryString from 'query-string';
import { useCallback, useState } from 'react';
import { useParseMenu } from '@/hooks';
import { accountCode, accountLogin, accountLoginOut, queryMenuList } from '@/services';

import { setAuthToken } from '@/utils';

export default () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [originalMenuData, setOriginalMenuData] = useState<any[]>([]);

    const [userInfo, setUserInfo] = useSessionStorageState<any>(BUBU100_CACHE_USERINFO_KEY);

    const getMenu = useCallback(async () => {
        setLoading(true);

        try {
            const result = await queryMenuList('');
            setOriginalMenuData(result);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, []);

    const [menuData, menuMeta] = useParseMenu(originalMenuData);

    const getCaptcha = useCallback(async () => {
        try {
            const values = await accountCode();
            return values;
        } catch (error) {
            return undefined;
        }
    }, []);

    const logout = useCallback(async () => {
        try {
            await accountLoginOut();
            sessionStorage.clear();
            setUserInfo(undefined);
        } catch (error) {}

        history.push({
            pathname: '/user/login',
        });

        try {
            await clearDB();
        } catch (error) {}
    }, [setUserInfo]);

    const login = useCallback(
        async (payload: any, showModal: any) => {
            const {
                token,
                user = {},
                authToken,
                msg,
                sysLogo,
                sysName,
            } = await accountLogin({
                ...payload,
            });
            if (msg) {
                showModal();
                setLoading(false);
            }
            if (token) {
                setToken(token);
                BiuCue.Success('登录成功！');

                /**
                 * 系统相关的信息直接合并到用户对应的信息内部
                 */
                setUserInfo({ ...user, sysLogo, sysName });
                setAuthToken(authToken ?? '');

                const query = queryString.parse(history.location.search);
                if (!query?.routing || query?.routing === 'mdm') {
                    history.replace('/');
                } else {
                    history.replace(`/${query?.routing}`);
                }
            }
        },
        [setUserInfo],
    );

    return {
        loading,
        userInfo,
        menuData,
        menuMeta,

        getCaptcha,
        logout,
        login,
        getMenu,
        setUserInfo,
    };
};
