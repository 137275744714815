import { history } from '@umijs/max';
import { useDynamicList } from 'ahooks';
import { useCallback, useState } from 'react';

export default () => {
    const { list: showTabs, push, remove, resetList } = useDynamicList<any>([]);
    const [activeTab, setActiveTab] = useState('');

    const closeAllTabs = useCallback(() => {
        resetList([]);
        history.replace('/dashboard');
    }, [resetList]);

    const closeTab = useCallback(
        (path: string) => {
            const currentTabIndex = showTabs.findIndex(
                ({ path: currentPath }) => currentPath === path,
            );

            if (path === activeTab) {
                if (showTabs.length === 1) {
                    closeAllTabs();
                } else if (currentTabIndex === 0) {
                    history.push(showTabs[1].path);
                } else {
                    history.push(showTabs[currentTabIndex - 1].path);
                }
            }
            remove(currentTabIndex);
        },
        [activeTab, closeAllTabs, remove, showTabs],
    );

    const closeLeftTabs = useCallback(() => {
        const index = showTabs.findIndex((n: any) => n.path === activeTab);
        resetList(showTabs.slice(index));
    }, [activeTab, resetList, showTabs]);

    const closeRightTabs = useCallback(() => {
        const index = showTabs.findIndex((n: any) => n.path === activeTab);

        resetList(showTabs.slice(0, index + 1));
    }, [activeTab, resetList, showTabs]);

    const closeOtherTabs = useCallback(() => {
        resetList(showTabs.filter((n: any) => n.path === activeTab));
    }, [activeTab, resetList, showTabs]);

    const addNewTab = useCallback(
        (tab: any) => {
            push({ ...tab });
        },
        [push],
    );

    return {
        showTabs,
        closeAllTabs,
        closeTab,
        activeTab,
        setActiveTab,
        closeLeftTabs,
        closeRightTabs,
        closeOtherTabs,
        addNewTab,
    };
};
