import { BUBU100_CACHE_AUTH_BUTTON_KEY } from 'enjoy-link-antd-repack';
import { isArray } from 'lodash-es';
import { useEffect, useState } from 'react';
import { getButtonListData, getMenuTreeData, getMenuMetaData } from '@/utils';

export const useParseMenu = (originalMenuData: any[]) => {
    const [menuData, setMenuData] = useState<any[]>([]);
    const [menuMeta, setMenuMeta] = useState<any>({
        '/dashboard': { path: '/dashboard', type: 'MDM' },
    });

    useEffect(() => {
        if (!originalMenuData || !isArray(originalMenuData) || originalMenuData.length === 0)
            return;

        const resolve_menu = getMenuTreeData(originalMenuData);
        const button = getButtonListData(originalMenuData);

        const currentMenuMetaData = getMenuMetaData(originalMenuData);

        const reslove_button = button.reduce(
            (
                { resolveButtonList, resolveCodeList },
                {
                    btnChildern,
                    class: resolveClass,
                    menuChildern,
                    menuicon,
                    menuorder,
                    productid,
                    ...restProps
                },
            ) => {
                resolveButtonList.push({ ...restProps });
                resolveCodeList.push(restProps.menuPerms);

                return { resolveButtonList, resolveCodeList };
            },
            { resolveButtonList: [], resolveCodeList: [] },
        );

        sessionStorage.setItem(BUBU100_CACHE_AUTH_BUTTON_KEY, JSON.stringify(reslove_button));

        setMenuData(resolve_menu);
        setMenuMeta((state: any) => ({ ...state, ...currentMenuMetaData }));
    }, [originalMenuData]);

    return [menuData, menuMeta];
};

export default null;
