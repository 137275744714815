import { useSessionStorageState, useLocalStorageState } from 'ahooks';

export default () => {
    const [collapsed, setCollapsed] = useSessionStorageState<boolean>('Collapsed', {
        defaultValue: false,
    });

    const [isVertical, setIsVertical] = useLocalStorageState('JOY_HEADER_LAYOUT', {
        defaultValue: '1',
    });

    return {
        collapsed,
        isVertical,

        setCollapsed,
        setIsVertical,
    };
};
