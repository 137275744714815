import { useSessionStorageState, useLocalStorageState } from 'ahooks';
import { BUBU100_CACHE_WAREINFO_KEY } from 'enjoy-link-antd-repack';

export default () => {
    const [selectWarehouse, setSelectWarehouse] = useLocalStorageState<any>(
        BUBU100_CACHE_WAREINFO_KEY,
    );

    const [selectDepartment, setSelectDepartment] = useSessionStorageState<any>(
        'BUBU100_CACHE_CURRENT_ORG_KEY',
    );

    return {
        selectDepartment,
        selectWarehouse,

        setSelectDepartment,
        setSelectWarehouse,
    };
};
