// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/root/workspace/sso-admin/src/models/business';
import model_2 from '/root/workspace/sso-admin/src/models/general';
import model_3 from '/root/workspace/sso-admin/src/models/system';
import model_4 from '/root/workspace/sso-admin/src/models/tabbar';

export const models = {
model_1: { namespace: 'business', model: model_1 },
model_2: { namespace: 'general', model: model_2 },
model_3: { namespace: 'system', model: model_3 },
model_4: { namespace: 'tabbar', model: model_4 },
} as const
