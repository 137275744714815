import type { MenuDataItem } from '@ant-design/pro-components';

/**
 * 菜单查询并且拼接平铺
 * @returns antd 路由列表
 */
export const getMenuTreeData = (menuData: any[], parentPath: string[] = []) => {
    const flatMenuData = menuData.reduce<MenuDataItem[]>((arr, current) => {
        const { menuname, menuicon, productcode, menucode } = current;

        const menuTitle = (menuname || '未命名') as string;
        const menuIcon = menuicon as string;
        const menuType = productcode as string;
        const menuPath = menucode as string;

        /**
         * 没有关联系统类型的菜单不进行展示
         */
        if (!menuType) return arr;

        const { menuChildern = [] } = current;

        /**
         * SSO 菜单路径使用结构动态生成，使用最后一级菜单作为子系统路由
         */
        const menuPathGroup: string[] = [...parentPath];
        menuPathGroup.push(`/${menuPath}`);

        const routeData: MenuDataItem = {
            name: menuTitle,
            icon: menuIcon,
            path: menuPathGroup.join(''),
        };

        // 子节点进行处理，
        if (menuChildern.length > 0) {
            const menu = getMenuTreeData(menuChildern, menuPathGroup);
            routeData.children = [...menu];
        }

        return [...arr, routeData];
    }, []);

    return flatMenuData;
};

/**
 * 菜单查询并且拼接平铺
 * @returns antd 路由列表
 */
export const getButtonListData: (menuData: any[]) => any[] = (menuData) => {
    let menuLinkButtonList: any[] = [];
    menuData.forEach((current) => {
        const { productcode } = current;

        /**
         * 没有关联系统类型的菜单不进行展示
         */
        if (!productcode) return;

        const { menuChildern = [], btnChildern = [] } = current;
        menuLinkButtonList = [...menuLinkButtonList, ...btnChildern];

        // 子节点进行处理，
        if (menuChildern.length > 0) {
            const button = getButtonListData(menuChildern);
            menuLinkButtonList = [...menuLinkButtonList, ...button];
        }
    }, []);

    return menuLinkButtonList;
};

/**
 * 菜单查询并且拼接平铺
 */
export const getMenuMetaData = (menuData: any[], parentPath: string[] = []) => {
    const menuMetaData: Record<string, Record<string, any>> = menuData.reduce((obj, current) => {
        /**
         * 菜单地址，菜单名称，菜单标题，菜单关联系统
         */
        const { menuname, menuurl, productcode, menucode } = current;

        const menuTitle = (menuname || '未命名') as string;
        const menuPath = menucode as string;
        const menuType = productcode as string;

        const subSystemPath = (menuurl || '/') as string;

        /**
         * 没有关联系统类型的菜单不进行展示
         */
        if (!productcode) return obj;

        const { menuChildern = [] } = current;
        /**
         * SSO 菜单路径使用结构动态生成，使用最后一级菜单作为子系统路由
         */
        const menuPathGroup: string[] = [...parentPath];
        menuPathGroup.push(`/${menuPath}`);

        let currentObj = { ...obj };
        if (subSystemPath) {
            currentObj[menuPathGroup.join('')] = { name: menuTitle, path: menuurl, type: menuType };
        }
        if (menuChildern.length > 0) {
            currentObj = { ...currentObj, ...getMenuMetaData(menuChildern, menuPathGroup) };
        }
        return currentObj;
    }, {});

    return menuMetaData;
};
