import { GATEWAY_ROUTE_SSO, GATEWAY_ROUTE_MDM, getToken, request } from 'enjoy-link-antd-repack';

// 修改密码 需要用户账号
export const updatePasswordLog = (data: any) => {
    return request({
        apiName: 'mdm.user.updatePasswordLog',
        data,
        requestConfig: {
            GatewayRoute: GATEWAY_ROUTE_MDM,
            Version: '',
        },
    });
};

// 修改密码 不需要用户账号
export const updatePassword = (data: any) => {
    return request({
        apiName: 'mdm.user.updatePassword',
        data,
        requestConfig: {
            GatewayRoute: GATEWAY_ROUTE_MDM,
            Version: '',
        },
    });
};

export const queryMenuList = (code?: string) => {
    return request({
        apiName: 'sso.getMenuList',
        data: { token: getToken(), code },
        requestConfig: {
            GatewayRoute: GATEWAY_ROUTE_SSO,
            Version: '',
        },
    });
};

export const accountLogin = (data: any) =>
    request({
        apiName: 'sso.login',
        beanName: 'loginBean',
        data,
        requestConfig: {
            GatewayRoute: GATEWAY_ROUTE_SSO,
            Version: '',
        },
    });

export const accountCode = () => {
    return request({
        apiName: 'sso.getCode',
        requestConfig: {
            GatewayRoute: GATEWAY_ROUTE_SSO,
            Version: '',
        },
    });
};

export const accountLoginOut = () => {
    return request({
        apiName: 'sso.logout',
        requestConfig: {
            GatewayRoute: GATEWAY_ROUTE_SSO,
            Version: '',
        },
        showNotification: false,
    });
};

export const queryTopMessage = () => {
    return request({
        apiName: 'wms.mdm.MsgNoticeLogService.getPagerList',
        data: { token: getToken(), page: 1, size: 5, status: 0 },
        requestConfig: {
            GatewayRoute: GATEWAY_ROUTE_MDM,
            Version: '',
        },
    });
};
