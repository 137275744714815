const ENJOY_LINK_DEVELOP: string = 'BUBU_100_DEV';

export function setDevelopment(isDevelop?: boolean) {
    sessionStorage.setItem(ENJOY_LINK_DEVELOP, isDevelop ?? false ? '1' : '0');
}

export function getDevelopment() {
    return sessionStorage.getItem(ENJOY_LINK_DEVELOP) === '1';
}

const ENJOY_LINK_AUTH_TOKEN: Readonly<string> = 'BUBU_100_AUTHJWT';

export function setAuthToken(token: string) {
    sessionStorage.setItem(ENJOY_LINK_AUTH_TOKEN, token);
}

export function shouldVerifyWarehouse() {
    return location.pathname.startsWith('/warehouse/managent');
}
