// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/dashboard","id":"1"},"2":{"path":"/oms","redirect":"/OMS/dashboard","id":"2"},"3":{"path":"/wms","redirect":"/WMS/missionCenete","id":"3"},"4":{"path":"/404","redirect":"/dashboard","id":"4"},"5":{"path":"/user","id":"5"},"6":{"name":"login","path":"/user/login","parentId":"5","id":"6"},"7":{"path":"/","id":"7"},"8":{"name":"DownloadCenter","path":"/DownloadCenter","parentId":"7","id":"8"},"9":{"name":"MessageCenter","path":"/MessageCenter","parentId":"7","id":"9"},"10":{"path":"*","parentId":"7","id":"10"}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import('./EmptyRoute')),
'3': React.lazy(() => import('./EmptyRoute')),
'4': React.lazy(() => import('./EmptyRoute')),
'5': React.lazy(() => import('./EmptyRoute')),
'6': React.lazy(() => import(/* webpackChunkName: "layouts__loginLayout" */'@/layouts/loginLayout.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "layouts__pageLayout" */'@/layouts/pageLayout.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__downloadCenter__index" */'@/pages/downloadCenter/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__messageCenter" */'@/pages/messageCenter.tsx')),
'10': React.lazy(() => import('./EmptyRoute')),
},
  };
}
